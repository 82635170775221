export default [
  { CallingCode: "93", name: "Afghanistan", id: "AFG" },
  { CallingCode: "358", name: "Åland Islands", id: "ALA" },
  { CallingCode: "355", name: "Albania", id: "ALB" },
  { CallingCode: "213", name: "Algeria", id: "DZA" },
  { CallingCode: "1684", name: "American Samoa", id: "ASM" },
  { CallingCode: "376", name: "Andorra", id: "AND" },
  { CallingCode: "244", name: "Angola", id: "AGO" },
  { CallingCode: "1264", name: "Anguilla", id: "AIA" },
  { CallingCode: "672", name: "Antarctica", id: "ATA" },
  { CallingCode: "1268", name: "Antigua and Barbuda", id: "ATG" },
  { CallingCode: "54", name: "Argentina", id: "ARG" },
  { CallingCode: "374", name: "Armenia", id: "ARM" },
  { CallingCode: "297", name: "Aruba", id: "ABW" },
  { CallingCode: "61", name: "Australia", id: "AUS" },
  { CallingCode: "43", name: "Austria", id: "AUT" },
  { CallingCode: "994", name: "Azerbaijan", id: "AZE" },
  { CallingCode: "1242", name: "Bahamas", id: "BHS" },
  { CallingCode: "973", name: "Bahrain", id: "BHR" },
  { CallingCode: "880", name: "Bangladesh", id: "BGD" },
  { CallingCode: "1246", name: "Barbados", id: "BRB" },
  { CallingCode: "375", name: "Belarus", id: "BLR" },
  { CallingCode: "32", name: "Belgium", id: "BEL" },
  { CallingCode: "501", name: "Belize", id: "BLZ" },
  { CallingCode: "229", name: "Benin", id: "BEN" },
  { CallingCode: "1441", name: "Bermuda", id: "BMU" },
  { CallingCode: "975", name: "Bhutan", id: "BTN" },
  {
    CallingCode: "591",
    name: "Bolivia (Plurinational State of)",
    id: "BOL",
  },
  {
    CallingCode: "5997",
    name: "Bonaire, Sint Eustatius and Saba",
    id: "BES",
  },
  {
    CallingCode: "387",
    name: "Bosnia and Herzegovina",
    id: "BIH",
  },
  { CallingCode: "267", name: "Botswana", id: "BWA" },
  { CallingCode: "", name: "Bouvet Island", id: "BVT" },
  { CallingCode: "55", name: "Brazil", id: "BRA" },
  {
    CallingCode: "246",
    name: "British Indian Ocean Territory",
    id: "IOT",
  },
  { CallingCode: "673", name: "Brunei Darussalam", id: "BRN" },
  { CallingCode: "359", name: "Bulgaria", id: "BGR" },
  { CallingCode: "226", name: "Burkina Faso", id: "BFA" },
  { CallingCode: "257", name: "Burundi", id: "BDI" },
  { CallingCode: "238", name: "Cabo Verde", id: "CPV" },
  { CallingCode: "855", name: "Cambodia", id: "KHM" },
  { CallingCode: "237", name: "Cameroon", id: "CMR" },
  { CallingCode: "1", name: "Canada", id: "CAN" },
  { CallingCode: "1345", name: "Cayman Islands", id: "CYM" },
  {
    CallingCode: "236",
    name: "Central African Republic",
    id: "CAF",
  },
  { CallingCode: "235", name: "Chad", id: "TCD" },
  { CallingCode: "56", name: "Chile", id: "CHL" },
  { CallingCode: "86", name: "China", id: "CHN" },
  { CallingCode: "61", name: "Christmas Island", id: "CXR" },
  {
    CallingCode: "61",
    name: "Cocos (Keeling) Islands",
    id: "CCK",
  },
  { CallingCode: "57", name: "Colombia", id: "COL" },
  { CallingCode: "269", name: "Comoros", id: "COM" },
  { CallingCode: "242", name: "Congo", id: "COG" },
  {
    CallingCode: "243",
    name: "Congo (Democratic Republic of the)",
    id: "COD",
  },
  { CallingCode: "682", name: "Cook Islands", id: "COK" },
  { CallingCode: "506", name: "Costa Rica", id: "CRI" },
  { CallingCode: "225", name: "Côte d'Ivoire", id: "CIV" },
  { CallingCode: "385", name: "Croatia", id: "HRV" },
  { CallingCode: "53", name: "Cuba", id: "CUB" },
  { CallingCode: "599", name: "Curaçao", id: "CUW" },
  { CallingCode: "357", name: "Cyprus", id: "CYP" },
  { CallingCode: "420", name: "Czech Republic", id: "CZE" },
  { CallingCode: "45", name: "Denmark", id: "DNK" },
  { CallingCode: "253", name: "Djibouti", id: "DJI" },
  { CallingCode: "1767", name: "Dominica", id: "DMA" },
  { CallingCode: "1809", name: "Dominican Republic", id: "DOM" },
  { CallingCode: "593", name: "Ecuador", id: "ECU" },
  { CallingCode: "20", name: "Egypt", id: "EGY" },
  { CallingCode: "503", name: "El Salvador", id: "SLV" },
  { CallingCode: "240", name: "Equatorial Guinea", id: "GNQ" },
  { CallingCode: "291", name: "Eritrea", id: "ERI" },
  { CallingCode: "372", name: "Estonia", id: "EST" },
  { CallingCode: "251", name: "Ethiopia", id: "ETH" },
  {
    CallingCode: "500",
    name: "Falkland Islands (Malvinas)",
    id: "FLK",
  },
  { CallingCode: "298", name: "Faroe Islands", id: "FRO" },
  { CallingCode: "679", name: "Fiji", id: "FJI" },
  { CallingCode: "358", name: "Finland", id: "FIN" },
  { CallingCode: "33", name: "France", id: "FRA" },
  { CallingCode: "594", name: "French Guiana", id: "GUF" },
  { CallingCode: "689", name: "French Polynesia", id: "PYF" },
  {
    CallingCode: "",
    name: "French Southern Territories",
    id: "ATF",
  },
  { CallingCode: "241", name: "Gabon", id: "GAB" },
  { CallingCode: "220", name: "Gambia", id: "GMB" },
  { CallingCode: "995", name: "Georgia", id: "GEO" },
  { CallingCode: "49", name: "Germany", id: "DEU" },
  { CallingCode: "233", name: "Ghana", id: "GHA" },
  { CallingCode: "350", name: "Gibraltar", id: "GIB" },
  { CallingCode: "30", name: "Greece", id: "GRC" },
  { CallingCode: "299", name: "Greenland", id: "GRL" },
  { CallingCode: "1473", name: "Grenada", id: "GRD" },
  { CallingCode: "590", name: "Guadeloupe", id: "GLP" },
  { CallingCode: "1671", name: "Guam", id: "GUM" },
  { CallingCode: "502", name: "Guatemala", id: "GTM" },
  { CallingCode: "44", name: "Guernsey", id: "GGY" },
  { CallingCode: "224", name: "Guinea", id: "GIN" },
  { CallingCode: "245", name: "Guinea-Bissau", id: "GNB" },
  { CallingCode: "592", name: "Guyana", id: "GUY" },
  { CallingCode: "509", name: "Haiti", id: "HTI" },
  {
    CallingCode: "",
    name: "Heard Island and McDonald Islands",
    id: "HMD",
  },
  { CallingCode: "379", name: "Holy See", id: "VAT" },
  { CallingCode: "504", name: "Honduras", id: "HND" },
  { CallingCode: "852", name: "Hong Kong", id: "HKG" },
  { CallingCode: "36", name: "Hungary", id: "HUN" },
  { CallingCode: "354", name: "Iceland", id: "ISL" },
  { CallingCode: "91", name: "India", id: "IND" },
  { CallingCode: "62", name: "Indonesia", id: "IDN" },
  {
    CallingCode: "98",
    name: "Iran (Islamic Republic of)",
    id: "IRN",
  },
  { CallingCode: "964", name: "Iraq", id: "IRQ" },
  { CallingCode: "353", name: "Ireland", id: "IRL" },
  { CallingCode: "44", name: "Isle of Man", id: "IMN" },
  { CallingCode: "972", name: "Israel", id: "ISR" },
  { CallingCode: "39", name: "Italy", id: "ITA" },
  { CallingCode: "1876", name: "Jamaica", id: "JAM" },
  { CallingCode: "81", name: "Japan", id: "JPN" },
  { CallingCode: "44", name: "Jersey", id: "JEY" },
  { CallingCode: "962", name: "Jordan", id: "JOR" },
  { CallingCode: "76", name: "Kazakhstan", id: "KAZ" },
  { CallingCode: "254", name: "Kenya", id: "KEN" },
  { CallingCode: "686", name: "Kiribati", id: "KIR" },
  {
    CallingCode: "850",
    name: "Korea (Democratic People's Republic of)",
    id: "PRK",
  },
  { CallingCode: "82", name: "Korea (Republic of)", id: "KOR" },
  { CallingCode: "965", name: "Kuwait", id: "KWT" },
  { CallingCode: "996", name: "Kyrgyzstan", id: "KGZ" },
  {
    CallingCode: "856",
    name: "Lao People's Democratic Republic",
    id: "LAO",
  },
  { CallingCode: "371", name: "Latvia", id: "LVA" },
  { CallingCode: "961", name: "Lebanon", id: "LBN" },
  { CallingCode: "266", name: "Lesotho", id: "LSO" },
  { CallingCode: "231", name: "Liberia", id: "LBR" },
  { CallingCode: "218", name: "Libya", id: "LBY" },
  { CallingCode: "423", name: "Liechtenstein", id: "LIE" },
  { CallingCode: "370", name: "Lithuania", id: "LTU" },
  { CallingCode: "352", name: "Luxembourg", id: "LUX" },
  { CallingCode: "853", name: "Macao", id: "MAC" },
  {
    CallingCode: "389",
    name: "Macedonia (the former Yugoslav Republic of)",
    id: "MKD",
  },
  { CallingCode: "261", name: "Madagascar", id: "MDG" },
  { CallingCode: "265", name: "Malawi", id: "MWI" },
  { CallingCode: "60", name: "Malaysia", id: "MYS" },
  { CallingCode: "960", name: "Maldives", id: "MDV" },
  { CallingCode: "223", name: "Mali", id: "MLI" },
  { CallingCode: "356", name: "Malta", id: "MLT" },
  { CallingCode: "692", name: "Marshall Islands", id: "MHL" },
  { CallingCode: "596", name: "Martinique", id: "MTQ" },
  { CallingCode: "222", name: "Mauritania", id: "MRT" },
  { CallingCode: "230", name: "Mauritius", id: "MUS" },
  { CallingCode: "262", name: "Mayotte", id: "MYT" },
  { CallingCode: "52", name: "Mexico", id: "MEX" },
  {
    CallingCode: "691",
    name: "Micronesia (Federated States of)",
    id: "FSM",
  },
  {
    CallingCode: "373",
    name: "Moldova (Republic of)",
    id: "MDA",
  },
  { CallingCode: "377", name: "Monaco", id: "MCO" },
  { CallingCode: "976", name: "Mongolia", id: "MNG" },
  { CallingCode: "382", name: "Montenegro", id: "MNE" },
  { CallingCode: "1664", name: "Montserrat", id: "MSR" },
  { CallingCode: "212", name: "Morocco", id: "MAR" },
  { CallingCode: "258", name: "Mozambique", id: "MOZ" },
  { CallingCode: "95", name: "Myanmar", id: "MMR" },
  { CallingCode: "264", name: "Namibia", id: "NAM" },
  { CallingCode: "674", name: "Nauru", id: "NRU" },
  { CallingCode: "977", name: "Nepal", id: "NPL" },
  { CallingCode: "31", name: "Netherlands", id: "NLD" },
  { CallingCode: "687", name: "New Caledonia", id: "NCL" },
  { CallingCode: "64", name: "New Zealand", id: "NZL" },
  { CallingCode: "505", name: "Nicaragua", id: "NIC" },
  { CallingCode: "227", name: "Niger", id: "NER" },
  { CallingCode: "234", name: "Nigeria", id: "NGA" },
  { CallingCode: "683", name: "Niue", id: "NIU" },
  { CallingCode: "672", name: "Norfolk Island", id: "NFK" },
  {
    CallingCode: "1670",
    name: "Northern Mariana Islands",
    id: "MNP",
  },
  { CallingCode: "47", name: "Norway", id: "NOR" },
  { CallingCode: "968", name: "Oman", id: "OMN" },
  { CallingCode: "92", name: "Pakistan", id: "PAK" },
  { CallingCode: "680", name: "Palau", id: "PLW" },
  { CallingCode: "970", name: "Palestine, State of", id: "PSE" },
  { CallingCode: "507", name: "Panama", id: "PAN" },
  { CallingCode: "675", name: "Papua New Guinea", id: "PNG" },
  { CallingCode: "595", name: "Paraguay", id: "PRY" },
  { CallingCode: "51", name: "Peru", id: "PER" },
  { CallingCode: "63", name: "Philippines", id: "PHL" },
  { CallingCode: "64", name: "Pitcairn", id: "PCN" },
  { CallingCode: "48", name: "Poland", id: "POL" },
  { CallingCode: "351", name: "Portugal", id: "PRT" },
  { CallingCode: "1787", name: "Puerto Rico", id: "PRI" },
  { CallingCode: "974", name: "Qatar", id: "QAT" },
  { CallingCode: "383", name: "Republic of Kosovo", id: "KOS" },
  { CallingCode: "262", name: "Réunion", id: "REU" },
  { CallingCode: "40", name: "Romania", id: "ROU" },
  { CallingCode: "7", name: "Russian Federation", id: "RUS" },
  { CallingCode: "250", name: "Rwanda", id: "RWA" },
  { CallingCode: "590", name: "Saint Barthélemy", id: "BLM" },
  {
    CallingCode: "290",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    id: "SHN",
  },
  {
    CallingCode: "1869",
    name: "Saint Kitts and Nevis",
    id: "KNA",
  },
  { CallingCode: "1758", name: "Saint Lucia", id: "LCA" },
  {
    CallingCode: "590",
    name: "Saint Martin (French part)",
    id: "MAF",
  },
  {
    CallingCode: "508",
    name: "Saint Pierre and Miquelon",
    id: "SPM",
  },
  {
    CallingCode: "1784",
    name: "Saint Vincent and the Grenadines",
    id: "VCT",
  },
  { CallingCode: "685", name: "Samoa", id: "WSM" },
  { CallingCode: "378", name: "San Marino", id: "SMR" },
  {
    CallingCode: "239",
    name: "Sao Tome and Principe",
    id: "STP",
  },
  { CallingCode: "966", name: "Saudi Arabia", id: "SAU" },
  { CallingCode: "221", name: "Senegal", id: "SEN" },
  { CallingCode: "381", name: "Serbia", id: "SRB" },
  { CallingCode: "248", name: "Seychelles", id: "SYC" },
  { CallingCode: "232", name: "Sierra Leone", id: "SLE" },
  { CallingCode: "65", name: "Singapore", id: "SGP" },
  {
    CallingCode: "1721",
    name: "Sint Maarten (Dutch part)",
    id: "SXM",
  },
  { CallingCode: "421", name: "Slovakia", id: "SVK" },
  { CallingCode: "386", name: "Slovenia", id: "SVN" },
  { CallingCode: "677", name: "Solomon Islands", id: "SLB" },
  { CallingCode: "252", name: "Somalia", id: "SOM" },
  { CallingCode: "27", name: "South Africa", id: "ZAF" },
  {
    CallingCode: "500",
    name: "South Georgia and the South Sandwich Islands",
    id: "SGS",
  },
  { CallingCode: "211", name: "South Sudan", id: "SSD" },
  { CallingCode: "34", name: "Spain", id: "ESP" },
  { CallingCode: "94", name: "Sri Lanka", id: "LKA" },
  { CallingCode: "249", name: "Sudan", id: "SDN" },
  { CallingCode: "597", name: "Suriname", id: "SUR" },
  {
    CallingCode: "4779",
    name: "Svalbard and Jan Mayen",
    id: "SJM",
  },
  { CallingCode: "268", name: "Swaziland", id: "SWZ" },
  { CallingCode: "46", name: "Sweden", id: "SWE" },
  { CallingCode: "41", name: "Switzerland", id: "CHE" },
  { CallingCode: "963", name: "Syrian Arab Republic", id: "SYR" },
  {
    CallingCode: "886",
    name: "Taiwan, Province of China",
    id: "TWN",
  },
  { CallingCode: "992", name: "Tajikistan", id: "TJK" },
  {
    CallingCode: "255",
    name: "Tanzania, United Republic of",
    id: "TZA",
  },
  { CallingCode: "66", name: "Thailand", id: "THA" },
  { CallingCode: "670", name: "Timor-Leste", id: "TLS" },
  { CallingCode: "228", name: "Togo", id: "TGO" },
  { CallingCode: "690", name: "Tokelau", id: "TKL" },
  { CallingCode: "676", name: "Tonga", id: "TON" },
  { CallingCode: "1868", name: "Trinidad and Tobago", id: "TTO" },
  { CallingCode: "216", name: "Tunisia", id: "TUN" },
  { CallingCode: "90", name: "Turkey", id: "TUR" },
  { CallingCode: "993", name: "Turkmenistan", id: "TKM" },
  {
    CallingCode: "1649",
    name: "Turks and Caicos Islands",
    id: "TCA",
  },
  { CallingCode: "688", name: "Tuvalu", id: "TUV" },
  { CallingCode: "256", name: "Uganda", id: "UGA" },
  { CallingCode: "380", name: "Ukraine", id: "UKR" },
  { CallingCode: "971", name: "United Arab Emirates", id: "ARE" },
  {
    CallingCode: "44",
    name: "United Kingdom of Great Britain and Northern Ireland",
    id: "GBR",
  },
  {
    CallingCode: "",
    name: "United States Minor Outlying Islands",
    id: "UMI",
  },
  {
    CallingCode: "1",
    name: "United States of America",
    id: "USA",
  },
  { CallingCode: "598", name: "Uruguay", id: "URY" },
  { CallingCode: "998", name: "Uzbekistan", id: "UZB" },
  { CallingCode: "678", name: "Vanuatu", id: "VUT" },
  {
    CallingCode: "58",
    name: "Venezuela (Bolivarian Republic of)",
    id: "VEN",
  },
  { CallingCode: "84", name: "Viet Nam", id: "VNM" },
  {
    CallingCode: "1284",
    name: "Virgin Islands (British)",
    id: "VGB",
  },
  {
    CallingCode: "1 340",
    name: "Virgin Islands (U.S.)",
    id: "VIR",
  },
  { CallingCode: "681", name: "Wallis and Futuna", id: "WLF" },
  { CallingCode: "212", name: "Western Sahara", id: "ESH" },
  { CallingCode: "967", name: "Yemen", id: "YEM" },
  { CallingCode: "260", name: "Zambia", id: "ZMB" },
  { CallingCode: "263", name: "Zimbabwe", id: "ZWE" },
];
